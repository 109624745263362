import { Block, Heading2, LinkTarget, Spacer, Stack, TypographyVariant, FilterType, } from '@humanfirst/elektron';
import { EvidenceResource, useElektraApi } from '@humanfirst/use-elektra-api';
import React, { useMemo } from 'react';
import { When } from 'react-if';
import { EditFlagTooltip } from 'src/components/EditFlagTooltip';
import { HelpCenterLink } from 'src/components/HelpCenterLink';
import { PATHS } from 'src/config/path';
import { useHasPermission } from 'src/hooks/permissions/useHasPermission';
import { RankedEvidenceStack } from 'src/components/RankedEvidence';
import { useGetConnectInstrumentScopes } from 'src/hooks/connect/connect';
import { LockableEvidenceTable } from './LockableEvidenceTable';
const RankedEvidenceStackForTool = ({ tool, }) => {
    const { data: evidence } = useElektraApi(EvidenceResource.list({
        filter: [['tools', '$cont', tool.id]],
    }));
    return React.createElement(RankedEvidenceStack, { evidence: evidence !== null && evidence !== void 0 ? evidence : [] });
};
export const ProductEvidenceTable = ({ tool }) => {
    const showDetailedEvidence = useHasPermission('component.evidence', 'read', tool);
    const scope = useMemo(() => {
        return [
            {
                fieldId: 'tools',
                filterType: FilterType.EQUALS,
                filterValue: `${tool.model}||${tool.id}`,
            },
        ];
    }, [tool.id, tool.model]);
    const { evidenceTableScopes } = useGetConnectInstrumentScopes();
    const finalScope = [...scope, ...evidenceTableScopes];
    return (React.createElement(Block, null,
        React.createElement(LinkTarget, { id: "evidence" }),
        React.createElement(When, { condition: showDetailedEvidence },
            React.createElement(Stack, { direction: "horizontal" },
                React.createElement(Heading2, { variant: TypographyVariant.Heading2Bold, marginBottom: "32px" }, "Product Evidence"),
                React.createElement(HelpCenterLink, { href: PATHS.helpCenter.usingAtlas.measuresAndEvidence.evidenceIngestion }),
                React.createElement(EditFlagTooltip, { field: "Product Evidence", label: "Please provide links to evidence you would like to add", hasComments: true, rows: 1 })),
            React.createElement(RankedEvidenceStackForTool, { tool: tool }),
            React.createElement(Spacer, { size: "64px" })),
        React.createElement(When, { condition: showDetailedEvidence },
            React.createElement(LockableEvidenceTable, { isLocked: false, scope: finalScope }))));
};
