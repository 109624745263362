import React, { useState } from 'react';
import { Block } from '@humanfirst/elektron';
import { Outlet } from 'react-router';
import { useTrackActivityPageviews } from 'src/hooks/activity/activity';
import { useUserProfileQuestionnaire } from 'src/hooks/userProfileQuestionnaire';
import { SideNavigation } from '../SideNavigation';
import { TopNavigationBar } from '../TopNavigationBar';
import { ErrorBoundary } from '../ErrorBoundary';
import { SubscribeToEmailsTray } from '../SubscribeToEmailsTray';
import { StatusIndicator } from '../StatusIndicator';
const PageLayout = () => {
    const [sideNavigationOpen, setSideNavigationOpen] = useState(true);
    // Activity tracking should only be applied post authentication.
    useTrackActivityPageviews();
    useUserProfileQuestionnaire();
    return (React.createElement(Block, { display: 'flex', flexDirection: 'column', height: "100vh" },
        React.createElement(Block, { overflow: 'hidden' },
            React.createElement(TopNavigationBar, { setIsOpen: setSideNavigationOpen, isOpen: sideNavigationOpen })),
        React.createElement(Block, { display: "flex", overflow: "auto", flex: "1" },
            React.createElement(SideNavigation, { isOpen: sideNavigationOpen, setIsOpen: setSideNavigationOpen }),
            React.createElement(ErrorBoundary, null,
                React.createElement(Block, { overflow: "auto", flex: "1", position: "relative" },
                    React.createElement(StatusIndicator, { padding: "16px" }),
                    React.createElement(Outlet, null)))),
        React.createElement(SubscribeToEmailsTray, null)));
};
export { PageLayout };
